import { FC, useCallback, useMemo } from 'react';
import { FilterProps } from './FilterProps';
import { useTableViewFilters } from '../../../contexts/table-view/TableViewFilterContext';
import { CurrencyFilterOptions, CurrencyFilterValue } from '../../../models/TableViewFilters';
import { useTranslation } from 'react-i18next';
import RadioButton from '../../shared/form-control/RadioButton';
import { InputStyle } from '../../shared/form-control/Input';
import CurrencyInput from '../../shared/CurrencyInput';

const defaultCurrency = {
  currencyCode: 'EUR',
  amount: '0.00',
};

const CurrencyFilter: FC<FilterProps> = (props) => {
  const { columnConfig, selectedTemplateId } = props;
  const { filters, setFilters } = useTableViewFilters();
  const { t } = useTranslation('table-view');

  const filterValue = useMemo(() => {
    return filters?.[selectedTemplateId]?.[columnConfig.value]?.filter as CurrencyFilterValue | undefined;
  }, [columnConfig.value, filters, selectedTemplateId]);

  const defaultExact = useMemo(() => filterValue?.exact ?? defaultCurrency, [filterValue?.exact]);

  const defaultMoreThan = useMemo(() => filterValue?.moreThan ?? defaultCurrency, [filterValue?.moreThan]);

  const defaultLessThan = useMemo(() => filterValue?.lessThan ?? defaultCurrency, [filterValue?.lessThan]);

  const defaultBetween = useMemo(
    () => ({
      start: filterValue?.between?.start ?? defaultCurrency,
      end: filterValue?.between?.end ?? defaultCurrency,
    }),
    [filterValue?.between?.end, filterValue?.between?.start],
  );

  const updateFilter = useCallback(
    (selectedOption: CurrencyFilterOptions, newValue: Partial<CurrencyFilterValue> = {}) => {
      const updatedFilter: CurrencyFilterValue = {
        selectedOption,
        exact: selectedOption === CurrencyFilterOptions.EXACT ? (newValue.exact ?? defaultExact) : null,
        moreThan: selectedOption === CurrencyFilterOptions.MORE_THAN ? (newValue.moreThan ?? defaultMoreThan) : null,
        lessThan: selectedOption === CurrencyFilterOptions.LESS_THAN ? (newValue.lessThan ?? defaultLessThan) : null,
        between: selectedOption === CurrencyFilterOptions.BETWEEN ? (newValue.between ?? defaultBetween) : null,
      };

      setFilters(columnConfig, selectedTemplateId, updatedFilter);
    },
    [columnConfig, defaultBetween, defaultExact, defaultLessThan, defaultMoreThan, selectedTemplateId, setFilters],
  );

  return (
    <div className="h-60 w-72 space-y-4">
      <RadioButton
        value={filterValue?.selectedOption === CurrencyFilterOptions.EXACT}
        size="small"
        label={t(`filters.progress.${CurrencyFilterOptions.EXACT}`)}
        onChange={() => updateFilter(CurrencyFilterOptions.EXACT)}
      />
      {filterValue?.selectedOption === CurrencyFilterOptions.EXACT && (
        <div className="relative flex items-center space-x-2">
          <CurrencyInput
            value={defaultExact}
            canChange
            onChange={(val) => updateFilter(CurrencyFilterOptions.EXACT, { exact: val })}
            style={InputStyle.MINIMAL}
            isFixedPosition={false}
          />
        </div>
      )}

      <RadioButton
        value={filterValue?.selectedOption === CurrencyFilterOptions.LESS_THAN}
        size="small"
        label={t(`filters.progress.${CurrencyFilterOptions.LESS_THAN}`)}
        onChange={() => updateFilter(CurrencyFilterOptions.LESS_THAN)}
      />
      {filterValue?.selectedOption === CurrencyFilterOptions.LESS_THAN && (
        <div className="relative flex items-center space-x-2">
          <CurrencyInput
            value={defaultLessThan}
            canChange
            onChange={(val) => updateFilter(CurrencyFilterOptions.LESS_THAN, { lessThan: val })}
            style={InputStyle.MINIMAL}
            isFixedPosition={false}
          />
        </div>
      )}

      <RadioButton
        value={filterValue?.selectedOption === CurrencyFilterOptions.MORE_THAN}
        size="small"
        label={t(`filters.progress.${CurrencyFilterOptions.MORE_THAN}`)}
        onChange={() => updateFilter(CurrencyFilterOptions.MORE_THAN)}
      />
      {filterValue?.selectedOption === CurrencyFilterOptions.MORE_THAN && (
        <div className="relative flex items-center space-x-2">
          <CurrencyInput
            value={defaultMoreThan}
            canChange
            onChange={(val) => updateFilter(CurrencyFilterOptions.MORE_THAN, { moreThan: val })}
            style={InputStyle.MINIMAL}
            isFixedPosition={false}
          />
        </div>
      )}

      <RadioButton
        value={filterValue?.selectedOption === CurrencyFilterOptions.BETWEEN}
        size="small"
        label={t(`filters.progress.${CurrencyFilterOptions.BETWEEN}`)}
        onChange={() => updateFilter(CurrencyFilterOptions.BETWEEN, { between: defaultBetween })}
      />
      {filterValue?.selectedOption === CurrencyFilterOptions.BETWEEN && (
        <div>
          <CurrencyInput
            value={defaultBetween.start}
            canChange
            onChange={(val) =>
              updateFilter(CurrencyFilterOptions.BETWEEN, {
                between: {
                  ...defaultBetween,
                  start: val,
                  end: {
                    ...defaultBetween.end,
                    currencyCode: val.currencyCode,
                  },
                },
              })
            }
            style={InputStyle.MINIMAL}
            isFixedPosition={false}
          />

          <div>{t('filters.date.and')}</div>

          <CurrencyInput
            value={defaultBetween.end}
            canChange={false}
            onChange={(val) =>
              updateFilter(CurrencyFilterOptions.BETWEEN, {
                between: { ...defaultBetween, end: { ...val, currencyCode: defaultBetween.start.currencyCode } },
              })
            }
            style={InputStyle.MINIMAL}
            isFixedPosition={false}
          />
        </div>
      )}
    </div>
  );
};

export default CurrencyFilter;
